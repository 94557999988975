export const strawberryBanana = {
  id: "smoothieStrawberryBanana",
  backgroundColor: "#8D1055",
  textColor: "#FFFFFF",
  name: "Strawberry Banana",
  tagline: "You'll Find This Very Ap-PEEL-ing",
  description:
    "The perfect smoothie: sweet strawberries, ap-PEEL-ing bananas. With a fruity dynamic duo like this, it would be berry rude not to take a sip, so you might as well drink the whole thing.",
  ingredients:
    "WATER, BANANA PUREE (BANANAS, CITRIC ACID, ASCORBIC ACID), STRAWBERRY PUREE, SUGAR, APPLE JUICE CONCENTRATE, CONTAINS LESS THAN 2% OF THE FOLLOWING: CITRIC ACID, LOCUST BEAN GUM, MODIFIED CELLULOSE, COLORED WITH (RED BEET JUICE CONCENTRATE), NATURAL FLAVOR, PECTIN,",
  disclaimer:
    "MAY CONTAIN PEANUTS, TREE NUTS, EGGS, MILK, SOY AND WHEAT FROM MANUFACTURING AND BLENDING. DERIVED FROM BIOENGINEERING",
  nutritionFacts: {
    servingSize: "12 fl oz (355 mL) as prepared",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Total Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Saturated Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Trans Fat",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "10mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Total Carbohydrate",
        value: "46g",
        percent: "17%",
      },
      {
        type: "secondary",
        label: "Dietary Fiber",
        value: "2g",
        percent: "8%",
      },
      {
        type: "secondary",
        label: "Total Sugars",
        value: "42g",
        percent: "",
      },
      {
        type: "tertiary",
        label: "Includes 28g Added Sugars",
        value: "",
        percent: "56%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "1g",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Vitamin D",
        value: "0.3mcg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.6mg",
        percent: "4%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "400mg",
        percent: "8%",
      },
    ],
  },
}

export const mango = {
  id: "smoothieMango",
  backgroundColor: "#FFA832",
  textColor: "#004E7D",
  name: "Mango",
  tagline: "All the Tropical Feels",
  description:
    "Warning: May produce feelings of relaxation and euphoria, so take it slow and enjoy the fruitful taste of the tropics. Your staycation begins now.",
  ingredients:
    "WATER, BANANA PUREE (BANANAS, CITRIC ACID, ASCORBIC ACID), MANGO CONCENTRATE, APPLE JUICE CONCENTRATE, SUGAR, PINEAPPLE JUICE CONCENTRATE, CONTAINS LESS THAN 2% OF THE FOLLOWING: CITRIC ACID, COLORED WITH (PUMPKIN JUICE CONCENTRATE), LOCUST BEAN GUM, NATURAL FLAVOR, MODIFIED CELLULOSE, TO PRESERVE FRESHNESS (POTASSIUM SORBATE), COLORED WITH (ANNATTO), PECTIN.",
  disclaimer:
    "MAY CONTAIN PEANUTS, TREE NUTS, EGGS, MILK, SOY AND WHEAT FROM MANUFACTURING AND BLENDING. DERIVED FROM BIOENGINEERING",
  nutritionFacts: {
    servingSize: "12 fl oz (355 mL) as prepared",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Total Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Saturated Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Trans Fat",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "5mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Total Carbohydrate",
        value: "46g",
        percent: "15%",
      },
      {
        type: "secondary",
        label: "Dietary Fiber",
        value: "2g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Total Sugars",
        value: "42g",
        percent: "",
      },
      {
        type: "tertiary",
        label: "Includes 19g Added Sugars",
        value: "",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "1g",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Vitamin D",
        value: "0.2mcg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "20mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.3mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "390mg",
        percent: "10%",
      },
    ],
  },
}

export const acaiBerryBlast = {
  id: "smoothieAcaiBerryBlast",
  backgroundColor: "#853C88",
  textColor: "#FFFFFF",
  name: "Acai Berry Blast",
  tagline: "The f'realist Acai Berry Blast Smoothie You've Ever Had",
  description:
    "In the mood for something SUPER refreshing and delicious? Our plant-based Acai smoothie is packed with real fruit to satisfy your sweet tooth.",
  ingredients:
    "WATER, APPLE JUICE CONCENTRATE, BANANA PUREE (BANANAS, CITRIC ACID, ASCORBIC ACID), ACAI PUREE (ACAI PULP, CITRIC ACID), SUGAR, CONTAINS LESS THAN 2% OF THE FOLLOWING: COLORED WITH (CARROT JUICE AND BLUEBERRY JUICE), NATURAL FLAVOR, BLACKBERRY JUICE CONCENTRATE, LOCUST BEAN GUM, MODIFIED CELLULOSE, CITRIC ACID, CARBOHYDRATE GUM, PECTIN.",
  disclaimer:
    "MAY CONTAIN PEANUTS, TREE NUTS, EGGS, MILK, SOY AND WHEAT FROM MANUFACTURING AND BLENDING. DERIVED FROM BIOENGINEERING",
  nutritionFacts: {
    servingSize: "12 fl oz (355 mL) as prepared",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Total Fat",
        value: "0.5g",
        percent: "1%",
      },
      {
        type: "secondary",
        label: "Saturated Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Trans Fat",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "10mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Total Carbohydrate",
        value: "44g",
        percent: "16%",
      },
      {
        type: "secondary",
        label: "Dietary Fiber",
        value: "2g",
        percent: "8%",
      },
      {
        type: "secondary",
        label: "Total Sugars",
        value: "39g",
        percent: "",
      },
      {
        type: "tertiary",
        label: "Includes 26g Added Sugars",
        value: "",
        percent: "53%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "1g",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Vitamin D",
        value: "0.3mcg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.2mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "380mg",
        percent: "8%",
      },
    ],
  },
}
