import React from "react"
import styled from "styled-components"

import NutritionFacts from "./NutritionFacts"

const Wrapper = styled.div`
  padding: 1rem;

  background-color: ${props => props.theme.color.whiteBg};

  color: ${props => props.theme.color.primary};
`
const Columns = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const FactsColumn = styled.div`
  flex-shrink: 0;
  margin: 1rem;

  @media (min-width: 1025px) {
    order: 1;
    flex-basis: 20rem;
  }
`
const ContentColumn = styled.div`
  flex-grow: 1;
  margin: 1rem;

  font-size: 18px;

  @media (min-width: 1025px) {
    order: 2;
    flex-basis: 30rem;
  }
`

const Description = styled.div`
  margin: 0 0 1.5rem;
  padding: 0 0 1.5rem;

  font-weight: bold;

  border-bottom: 2px solid ${props => props.theme.color.primary};
`
const Ingredients = styled.div`
  margin: 0 0 1.5rem;
`
Ingredients.Title = styled.div`
  margin: 0 0 1rem;

  font-weight: bold;
  text-transform: uppercase;
`
const Disclaimer = styled.div`
  font-style: italic;
`

export default function Content(props) {
  const { data } = props

  return (
    <Wrapper>
      <Columns>
        <ContentColumn>
          {data && data.description && (
            <Description>{data.description}</Description>
          )}

          {data && data.ingredients && (
            <Ingredients>
              <Ingredients.Title>Ingredients</Ingredients.Title>
              <div>{data.ingredients}</div>
            </Ingredients>
          )}

          {data && data.disclaimer && (
            <Disclaimer dangerouslySetInnerHTML={{ __html: data.disclaimer }} />
          )}
        </ContentColumn>
        <FactsColumn>
          {data && data.nutritionFacts && (
            <NutritionFacts nutritionFacts={data.nutritionFacts} />
          )}
        </FactsColumn>
      </Columns>
    </Wrapper>
  )
}
