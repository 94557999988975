import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Flavor from "../../components/Flavor/"
import {
  TypeTitle,
  TypeDescription,
  CardRow,
} from "../../components/Flavor/Card"

import {
  strawberryBanana,
  mango,
  acaiBerryBlast,
} from "../../data/smoothiesData"

export default function Smoothies(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsSmoothies {
      strawberryBanana: file(
        relativePath: {
          eq: "front-page-flavors/smoothie-strawberry-banana.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            quality: 100
            layout: FIXED
          )
        }
      }

      mango: file(
        relativePath: { eq: "front-page-flavors/smoothie-mango.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            quality: 100
            layout: FIXED
          )
        }
      }

      acaiBerryBlast: file(
        relativePath: { eq: "front-page-flavors/smoothie-acai-berry-blast.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            quality: 100
            layout: FIXED
          )
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Smoothies</TypeTitle>
      <TypeDescription>
        Made with real fruit, our smoothies are now plant-based and with low
        sugar added! Try our new Acai Berry Blast Smoothie!
      </TypeDescription>

      <CardRow>
        <Flavor
          data={strawberryBanana}
          imageData={images.strawberryBanana.childImageSharp.gatsbyImageData}
          flavorIndex={8}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={mango}
          imageData={images.mango.childImageSharp.gatsbyImageData}
          flavorIndex={9}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={acaiBerryBlast}
          imageData={images.acaiBerryBlast.childImageSharp.gatsbyImageData}
          flavorIndex={10}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
