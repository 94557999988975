import React from "react"
import styled from "styled-components"

import { hex2rgba } from "../../../styles/tools"

const Title = styled.strong`
  display: block;
  margin: 0 0 0.675rem;
  padding: 0 0 0.75rem;

  border-bottom: 2px solid ${props => props.theme.color.primary};

  text-transform: uppercase;
  font-size: 18px;
`
const Row = styled.div`
  padding: 0.375rem 0;

  border-bottom: 2px solid
    ${props => hex2rgba(props.theme.color.primary, 0.125)};
`
const FlexRow = styled(Row)`
  display: flex;

  &[data-justify="space-between"] {
    justify-content: space-between;
  }
  &[data-justify="flex-end"] {
    justify-content: flex-end;
  }

  span {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`
const SeparatorRow = styled(Row)`
  border-bottom: 6px solid ${props => props.theme.color.primary};
`
const ValueRow = styled(Row)`
  display: flex;

  &[data-type="secondary"] {
    padding-left: 1rem;
  }
  &[data-type="tertiary"] {
    padding-left: 2rem;
  }
`
const Label = styled.span`
  &[data-bold="true"] {
    font-weight: bold;
  }
`
const Value = styled.span`
  display: inline-block;
  flex-grow: 1;
  margin-left: 0.875rem;
`
const Percent = styled.span`
  display: inline-block;
  margin-left: 0.875rem;

  font-weight: bold;
`

export default function NutritionFacts(props) {
  const { nutritionFacts } = props

  return (
    <>
      <Title>Nutrition Facts</Title>

      {nutritionFacts && (
        <>
          <SeparatorRow>
            <div>Serving size {nutritionFacts.servingSize}</div>
            <div>1 servings per container</div>
          </SeparatorRow>
          <SeparatorRow>
            <div>Amount per serving</div>
            <strong>Calories {nutritionFacts.calories}</strong>
          </SeparatorRow>
          <FlexRow data-justify="flex-end">% Daily Value</FlexRow>
          {nutritionFacts.values.map((value, valueIndex) => (
            <ValueRow key={valueIndex} data-type={value.type}>
              <Label data-bold={value.type === "primary"}>{value.label}</Label>
              <Value>{value.value}</Value>
              <Percent>{value.percent}</Percent>
            </ValueRow>
          ))}
        </>
      )}
    </>
  )
}
